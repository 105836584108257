import https from '@/router/https'

export function getType(param) {
    return https.fetchGet('/mallapp/goodsspuspec/tree', param);
}

export function addShopCar(param) {
    return https.fetchPost('/mallapp/shoppingcart', param);
}

export function insertMyshop(param) {
    return https.fetchPost('/mallapp/goodsspu/insertMyshop', param);
}
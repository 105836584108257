import https from '@/router/https'
//自营商品详情

export function getObj(id) {
    return https.fetchGet('/mallapp/goodsspu/' + id, );
}
//上架到我的小店 
export function userCollect(param) {
    return https.fetchPost('/mallapp/goodsspu/insertMyshop', param);
}
//店铺详情页
export function getGoodDetail(id) {
    return https.fetchGet('/mallapp/shopinfo/'+ id,);
}
//获取收货地址
export function geGuesstObj(param) {
    return https.fetchGet('/mallapp/useraddress/page', param);
}
//收藏
export function getFavoritesr(param)
{
    return https.fetchPost('/mallapp/usercollect', param );
}
//取消收藏
export function getNoFavoritesr(param)
{
    return https.fetchPost('/mallapp/usercollect/del', param );
}
//加入购物车
export function getShopping(param)
{
    return https.fetchPost('/mallapp/shoppingcart', param  );
}
//分类
export function goodsCategory(param)
{
    return https.fetchGet('/mallapp/goodscategory/tree', param );
}
//分类
export function goodsCategoryobj(param)
{
    return https.fetchGet('/mallapp/goodscategory/page', param );
}
//分类详情（商品列表）
export function goodsCategoryDetail(param)
{
    return https.fetchGet('/mallapp/goodsspu/page', param );
}

<template>
  <div class="detailsGoods" v-if="drivings.shopInfo.imgUrl">
    <div style="position: fixed; top: 0; left: 0; right: 0; z-index: 10">
      <van-nav-bar title="商品" left-arrow @click-left="$goBack()">
        <template #right>
          <img
            class="star"
            @click="shouchang"
            v-if="drivings.collectId == null"
            src="../../assets/img/Star.png"
            alt=""
          />
          <img
            class="star"
            @click="cancelCellect"
            v-if="drivings.collectId"
            src="../../assets/img/readstar.png"
            alt=""
          />
          <van-icon
            name="share-o"
            size="18"
            color="#000"
            @click="shareBoxShow = true"
          />
        </template>
      </van-nav-bar>
    </div>
    <!--商品图片 :autoplay="3000" -->
    <div class="goods">
      <van-swipe class="goods-swipe" :autoplay="3000" @change="swipeChange">
        <van-swipe-item
          v-for="(bandas, index) in drivings.picUrls"
          :key="index"
        >
          <van-image
            :src="bandas"
            fit="cover"
            style="width: 100vw; height: 100vw"
          />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            {{ swipeCurrent + 1 }}/{{ drivings.picUrls.length }}
          </div>
        </template>
      </van-swipe>

      <div class="goods-box">
        <!--商品标题-->
        <div
          style="
            display: flex;
            justify-content: space-between;
            padding: 10px 0 10px 16px;
          "
        >
          <span style="font-size: 17px; font-weight: 500; flex: 1">
            {{ drivings.name }}
          </span>
          <div
            @click="lead"
            class="linear-btn-color"
            style="
              flex-shrink: 0;
              padding-left: 10px;
              display: flex;
              align-items: center;
              height: 24px;
              color: #fff;
              font-size: 12px;
              border-radius: 12px 0 0 12px;
            "
          >
            <span>领券</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <!--价格 原价-->
        <div
          style="
            display: flex;
            padding: 0 16px 10px 16px;
            align-items: flex-end;
            justify-content: space-between;
            line-height: 1;
            margin: 6px 0;
          "
        >
          <div style="display: flex; align-items: flex-end">
            <div
              style="
                color: #a80000;
                font-size: 14px;
                font-weight: 500;
                padding-top: 10px;
              "
            >
              ￥
            </div>
            <div style="color: #a80000; font-size: 28px; font-weight: 500">
              {{ drivings.priceDown }}
            </div>
            <div
              class="details-Number"
              v-if="drivings.priceDown !== drivings.priceUp"
            >
              <div style="color: #a80000; font-size: 14px; font-weight: 500">
                ～
              </div>
              <div style="color: #a80000; font-size: 28px; font-weight: 500">
                {{ drivings.priceUp }}
              </div>
            </div>

            <del
              style="
                margin-left: 10px;
                font-size: 12px;
                color: #999999;
                padding-top: 10px;
              "
              >￥{{ drivings.marketPrice }}</del
            >
          </div>
          <div style="color: #999999">已售{{ drivings.saleNum }}</div>
        </div>
      </div>
      <!-- /选择配送 -->
      <div class="goods-box">
        <van-cell center is-link @click="skusSelect(0)">
          <template slot="title">
            <div style="display: flex; width: 100%">
              <div
                style="
                  font-size: 17px;
                  font-weight: 400;
                  margin-right: 10px;
                  display: flex;
                  width: 65px;
                  align-items: center;
                  flex-shrink: 0;
                "
              >
                选择
              </div>
              <div
                style="
                  font-size: 17px;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.3);
                "
                v-if="form.skus == ''"
              >
                款式/净含量/规格类型
              </div>
              <div v-else class="van-ellipsis">
                <span
                  style="
                    font-size: 17px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.3);
                  "
                  v-for="(item, index) in form.skus.specs"
                  :key="item.id + index"
                  v-show="form.skus != ''"
                >
                  {{ item.specValueName }}
                </span>
                <span
                  style="
                    font-size: 17px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.3);
                  "
                  v-if="form.skus != '' && form.skus.specs.length == 0"
                  >统一规格</span
                >
              </div>
            </div>
          </template>
        </van-cell>

        <van-cell center is-link @click="$router.push('/shippingAddress')">
          <template slot="title">
            <div style="display: flex; width: 100%">
              <div
                style="
                  font-size: 17px;
                  font-weight: 400;
                  margin-right: 10px;
                  display: flex;
                  width: 65px;
                  flex-shrink: 0;
                "
              >
                配送
              </div>
              <div>
                <div>
                  <span>快递：</span>
                  <span v-if="drivings.freightTemplat.type == 1"
                    >￥{{ drivings.freightTemplat.firstFreight }}起</span
                  >
                  <span v-if="drivings.freightTemplat.type == 2">{{
                    drivings.freightTemplat.name
                  }}</span>
                </div>
                <div
                  class="van-ellipsis"
                  style="
                    font-size: 17px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.3);
                  "
                >
                  {{
                    address == ""
                      ? "请选择配送地址"
                      : address.provinceName +
                        address.cityName +
                        address.countyName
                  }}
                </div>
              </div>
            </div>
          </template>
        </van-cell>

        <van-cell
          center
          is-link
          @click="ensureShow = true"
          v-if="ensureList.length"
        >
          <template slot="title">
            <div style="display: flex; width: 100%">
              <div
                style="
                  font-size: 17px;
                  font-weight: 400;
                  margin-right: 10px;
                  display: flex;
                  width: 65px;
                  align-items: center;
                  flex-shrink: 0;
                "
              >
                保障
              </div>
              <div
                class="van-ellipsis"
                style="font-size: 17px; font-weight: 400; color: #333"
              >
                {{ ensures }}
              </div>
            </div>
          </template>
        </van-cell>
      </div>

      <!--描述-->
      <div class="goods-box">
        <div style="display: flex; justify-content: space-between">
          <div
            style="
              line-height: 44px;
              font-size: 14px;
              font-weight: 600;
              color: #333333;
              margin-left: 10px;
            "
          >
            宝贝评价（{{ evaluateList.length }}条）
          </div>
          <div
            style="
              line-height: 44px;
              font-size: 14px;
              color: #ff5200;
              margin-right: 10px;
            "
            @click="$router.push('detailsGoodsEvaluate?id=' + id)"
          >
            <span>查看全部</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div v-if="evaluateList.length">
          <evaluate-item
            v-for="(item, index) in evaluateList"
            :key="index"
            :value="item"
          ></evaluate-item>
        </div>
        <div
          v-else
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            color: #999;
          "
        >
          暂无评价
        </div>
      </div>

      <div class="goods-box" style="padding: 10px">
        <div style="display: flex">
          <img
            :src="drivings.shopInfo.imgUrl ? drivings.shopInfo.imgUrl : ''"
            style="width: 60px; height: 60px"
            @click="
              $router.push({
                path: '/detailsPage',
                query: { id: drivings.shopId },
              })
            "
          />
          <div style="flex: 1; margin-left: 10px">
            <div
              style="font-size: 16px; color: #333333; line-height: 30px"
              @click="
                $router.push({
                  path: '/detailsPage',
                  query: { id: drivings.shopId },
                })
              "
            >
              <span>{{ drivings.shopInfo.name }}</span>
              <van-icon name="arrow" />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #999;
              "
            >
              <img
                v-if="drivings.shopInfo.isExacte == '1'"
                src="../../assets/img/yanxuan.png"
                style="width: 52px; height: 17px"
              />
              &nbsp;
              <span>
                {{ drivings.shopInfo.detail }}
              </span>
            </div>
          </div>
          <div>
            <div style="text-align: center">
              <span style="font-size: 14px; color: #999999; margin-right: 5px"
                >宝贝描述</span
              >
              <span style="font-size: 12px; color: #da0b06">{{
                drivings.score.goodsScoreAvg
                  ? (drivings.score.goodsScoreAvg - 0).toFixed(1)
                  : "0"
              }}</span>
            </div>
            <div style="text-align: center">
              <span style="font-size: 14px; color: #999999; margin-right: 5px"
                >卖家服务</span
              >
              <span style="font-size: 12px; color: #da0b06">{{
                drivings.score.serviceScoreAvg
                  ? (drivings.score.serviceScoreAvg - 0).toFixed(1)
                  : "0"
              }}</span>
            </div>
            <div style="text-align: center">
              <span style="font-size: 14px; color: #999999; margin-right: 5px"
                >物流服务</span
              >
              <span style="font-size: 12px; color: #da0b06">{{
                drivings.score.logisticsScoreAvg
                  ? (drivings.score.logisticsScoreAvg - 0).toFixed(1)
                  : "0"
              }}</span>
            </div>
          </div>
        </div>

        <!-- 推荐 -->
        <div
          class="van-multi-ellipsis--l2"
          style="display: flex; justify-content: space-around"
        >
          <div
            v-for="(bandas, index) in drivings.shopInfo.listGoodsSpu"
            :key="bandas.id + index"
            @click="goDetailsGoods(bandas.id)"
            style="width: 30%"
          >
            <div>
              <van-image
                :src="bandas.picUrls[0] ? bandas.picUrls[0] : ''"
                style="width: 90px; height: 90px"
              ></van-image>
              <div style="height: 40px" class="van-multi-ellipsis--l2">
                {{ bandas.name }}
              </div>
              <div style="font-size: 18px; font-weight: 500; color: #a80000">
                <span style="font-size: 14px">￥</span>
                <span>{{ bandas.priceDown }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--描述-->
      <div class="goods-box">
        <div
          style="
            line-height: 44px;
            font-size: 14px;
            font-weight: 600;
            color: #333333;
            margin-left: 10px;
          "
        >
          宝贝详情
        </div>
        <div v-html="drivings.description" style="padding: 10px"></div>
      </div>

      <div style="position: fixed; bottom: 100px; right: 20px">
        <div
          v-if="drivings.imAccount != null"
          style="
            width: 42px;
            height: 42px;
            border-radius: 50%;
            line-height: 1;
            text-align: center;
            background-color: #737073;
            color: #f9f9f9;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
          @click="$contact(drivings.imAccount)"
        >
          <div>
            <div style="font-size: 20px"><van-icon name="user-circle-o" /></div>
            <div style="font-size: 12px">客服</div>
          </div>
        </div>

        <div
          style="width: 42px; height: 42px; margin-bottom: 10px"
          @click="$router.push('/shoppingcart')"
        >
          <img src="img/detailsGoods/shopCar@2x.png" alt="" />
        </div>
        <!-- <div style="width: 42px; height: 42px" @click="$router.push('/MyShop')">
          <img src="img/detailsGoods/my@2x.png" alt="" />
        </div> -->
      </div>

      <!-- <van-goods-action fixed="true">
        <van-goods-action-icon
          icon="home-o"
          @click="
            $router.push({
              path: '/detailsPage',
              query: { id: drivings.shopId },
            })
          "
        >
          店铺
        </van-goods-action-icon>
        <van-goods-action-icon icon="cart-o" @click="skusSelect(1)">
          加入购物车
        </van-goods-action-icon>
        <van-goods-action-button type="warning" @click="skusSelect(2)">
          购买
        </van-goods-action-button>
      </van-goods-action> -->

      <van-popup
        round
        v-model="show"
        position="bottom"
        style="height: 60%"
        safe-area-inset-bottom
      >
        <div class="popCard">
          <van-card :thumb="popup.thumb">
            <template #price>
              <span style="color: #a80000; margin-left: 10px">{{
                popup.price
              }}</span>
            </template>
            <template #title>
              <div style="color: #999999; font-size: 12px; margin-left: 10px">
                {{ popup.title }}
              </div>
            </template>
            <template #desc>
              <div style="color: #333333; font-size: 12px; margin-left: 10px">
                {{ popup.desc }}
              </div>
            </template>
          </van-card>

          <van-button
            icon="close"
            type="default"
            @click="show = false"
            style="width: 24px; height: 24px; border: none"
          />
        </div>
        <div style="height: calc(100% - 186px); overflow: scroll">
          <div
            style="padding: 10px"
            v-for="(item, index) in typeArr"
            :key="item.id + index"
          >
            <div style="margin-bottom: 10px">{{ item.value }}</div>
            <div>
              <van-button
                v-for="(supItem, supIndex) in item.leaf"
                :key="supItem.id + supIndex"
                type="default"
                size="small"
                @click="ClickOrder(index, supItem)"
                :class="
                  selectType.indexOf(supItem.id) == -1 ? 'noSelect' : 'select'
                "
                style="margin-bottom: 10px"
              >
                {{ supItem.value }}
              </van-button>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              padding: 10px;
              align-items: center;
            "
          >
            <div>购买数量</div>
            <van-stepper @change="itemNumChange" v-model="form.num" />
          </div>
          <!-- <div style="height: 50px"></div> -->
        </div>
        <div
          @click="shoppIng"
          style="
            height: 40px;
            padding: 5px;
            padding-bottom: constant(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom);
            background-color: #fff;
            margin-bottom: 5px;
          "
        >
          <van-button
            type="warning"
            color="linear-gradient(to right, #FF7200, #FF3C00)"
            size="large"
            round
            style="height: 40px"
            >确定</van-button
          >
          <!-- <van-goods-action>
              <van-goods-action-button
                type="warning"
                color="linear-gradient(to right, #FF7200, #FF3C00)"
                text="确定"
              />
            </van-goods-action> -->
        </div>
      </van-popup>

      <van-popup
        v-model="shareBoxShow"
        position="bottom"
        style="height: 60px; padding: 20px 0"
      >
        <div class="bottom-list">
          <div style="width: 100px" @click="shareGoods(0)">
            <img src="/img/weixin.png" alt="" style="width: 32px" />
            <p>微信</p>
          </div>
          <div style="width: 100px" @click="shareGoods(1)">
            <img src="/img/pengyouquan.png" alt="" style="width: 32px" />
            <p>朋友圈</p>
          </div>
          <div style="width: 100px" @click="shareGoods(2)">
            <img src="/img/qq.png" alt="" style="width: 32px" />
            <p>QQ</p>
          </div>
        </div>
      </van-popup>

      <van-popup v-model="ensureShow" position="bottom" round closeable>
        <div
          style="
            height: 390px;
            padding-bottom: constant(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom);
          "
        >
          <div
            style="
              height: 24px;
              font-size: 17px;
              padding: 16px 0 10px;
              color: #333333;
              text-align: center;
            "
          >
            服务保障
          </div>
          <div style="height: 274px; overflow: auto">
            <div
              v-for="(item, index) in ensureList"
              :key="index"
              style="
                padding: 12px 0;
                margin: 0 16px;
                border-bottom: 1px solid #e5e5e5;
              "
            >
              <div style="font-size: 17px; font-weight: 600; color: #333333">
                {{ item.name }}
              </div>
              <div style="font-size: 14px; color: #999">
                {{ item.detail }}
              </div>
            </div>
          </div>
          <van-button
            size="small"
            color="linear-gradient(to right, #FF7200, #FF3C00)"
            style="
              height: 36px;
              margin: 10px 16px 20px;
              width: calc(100vw - 32px);
            "
            @click="ensureShow = false"
            >我 知 道 了</van-button
          >
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { getObj } from "@/api/proprietary/proprietary";
import {
  getType,
  addShopCar,
  insertMyshop,
} from "@/api/detailsGoods/detailsGoods";
import { getComment } from "../../api/hotelDetail/index";
import evaluateItem from "@/components/evaluate-item";
import { Toast } from "vant";
export default {
  name: "detailGoods",
  components: { evaluateItem },
  data() {
    return {
      swipeCurrent: 0,
      description: null,
      drivings: {
        shopInfo: {
          imgUrl: "",
        },
        score: { goodsScoreAvg: "" },
        picUrls: [],
      },
      typeArr: [],
      show: false,
      id: "",
      isShow: false,
      shareShow: false,
      popSureType: 0, //0选择规格1加入购物车2进入订单
      address: "",
      isFirst: false, //是否是第一页
      Show: false,
      popup: {
        price: "",
        desc: "",
        title: "",
        thumb: "",
      },
      selectType: [],
      form: {
        skus: "",
        num: 1,
        shopName: "",
        name: "",
      },
      shareForm: {
        message: "",
        addPrice: "0",
      },
      channelCid: null,

      shareBoxShow: false,
      evaluateList: [],
      ensureShow: false,
      ensureList: [],
      ensures: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "ShippingAddress") {
        if (from.params.address) vm.address = from.params.address;
      } else {
        vm.address = "";
      }
    });
  },
  created() {
    //  if(navigator.userAgent.indexOf('万民')==-1){
    //       this.openApp()
    //       return
    // }

    this.pageRefresh();
    this.spuId = this.$route.query.id;
    if (this.$route.query.channelCid) {
      this.channelCid = this.$route.query.channelCid;
    }
  },
  methods: {
    lead() {
      this.$router.push({
        path: "/coupon",
        query: {
          spuId: this.spuId,
        },
      });
    },
    //轮播图变换
    swipeChange(index) {
      this.swipeCurrent = index;
    },
    itemNumChange() {
      console.log(this.form.num);
    },
    //选择类型弹窗
    skusSelect(type) {
      if (this.drivings.skus.length == 0) {
        Toast("此商品暂无规格");
      } else {
        this.show = true;
        this.popSureType = type;
      }
    },
    //选择类型
    ClickOrder(index, supItem) {
      if (this.selectType.indexOf(supItem.id) == -1) {
        this.selectType.splice(index, 1, supItem.id);
      } else {
        this.selectType.splice(index, 1, "");
      }
      let isChoose = false;
      let selectItem;
      for (let item of this.drivings.skus) {
        let isSupChoose = true;
        for (let index = 0; index < item.specs.length; index++) {
          let specs = item.specs[index];
          if (specs.specValueId != this.selectType[index]) {
            isSupChoose = false;
            break;
          }
        }
        if (isSupChoose) {
          isChoose = true;
          selectItem = item;
          break;
        }
      }
      if (isChoose) {
        let desc = "";
        for (let index = 0; index < selectItem.specs.length; index++) {
          let specs = selectItem.specs[index];
          desc = desc.concat(specs.specValueName + " ");
        }
        this.popup = {
          price: "¥" + selectItem.salesPrice,
          desc: desc,
          title: "库存" + selectItem.stock + "个",
          thumb: selectItem.picUrl
            ? selectItem.picUrl
            : this.drivings.picUrls[0],
        };
        this.form.skus = selectItem;
      } else {
        this.popup = {
          price:
            "¥" +
            this.drivings.priceDown +
            (this.drivings.priceDown !== this.drivings.priceUp
              ? "～¥" + this.drivings.priceUp
              : ""),
          desc: "请选择：商品规格",
          title: "", //库存1000个
          thumb: this.drivings.picUrls[0] ? this.drivings.picUrls[0] : "",
        };
        this.form.skus = "";
      }

      console.log(this.form.skus);
    },
    //点击商品
    goDetailsGoods(id) {
      // this.$router.push({path: '/detailCommidity',query: {id: id}});
      this.id = id;
      this.getObjs();
    },
    //点击确定进入订单/添加购物车
    shoppIng() {
      // if()
      if (this.form.skus) {
        if (this.form.skus == "") {
          Toast("请选择商品规格");
          return;
        }
        if (this.form.skus.stock == 0) {
          Toast("当前规格暂无商品");
          return;
        }

        let cash = 0;
        let type = this.drivings.freightTemplat;

        if (type.chargeType == 1) {
          if (this.form.num >= type.firstNum) {
            cash = type.firstFreight;
          }

          if (this.form.num >= type.continueNum) {
            cash = type.continueFreight;
          }
        }

        if (type.chargeType == 2) {
          let weight = this.form.skus.weight * this.form.num;
          if (type.firstNum <= weight) {
            cash = type.firstFreight;
          }
          if (type.continueNum <= weight) {
            cash = type.continueFreight;
          }
        }

        if (type.chargeType == 3) {
          let volume = this.form.skus.volume * this.form.num;
          if (type.firstNum <= volume) {
            cash = type.firstFreight;
          }
          if (type.continueNum <= volume) {
            cash = type.continueFreight;
          }
        }

        this.form.freight = cash;

        if (this.popSureType == 0) {
          this.show = false;
        } else if (this.popSureType == 1) this.addShopCar();
        else {
          if (!this.form.skus.picUrl) {
            this.form.skus.picUrl = this.drivings.picUrls[0];
          }
          this.$router.push({
            path: "/sureorder",
            query: {
              info: [JSON.stringify(this.form)],
              address: JSON.stringify(this.address),
              channelCid: this.channelCid,
              shopId: this.drivings.shopInfo.id,
              type: "4",
              cash: cash,
            },
          });
        }
      } else {
        Toast("此商品暂无规格");
      }
    },
    Close() {
      if (this.isFirst) {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      } else {
        this.$router.back(-1);
        this.pageRefresh();
      }
    },
    //获取数据
    getObjs() {
      console.log(this.id);
      getObj(this.id).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.drivings = res.data.data;
          this.ensureList = this.drivings.ensures;
          this.ensures = this.ensureList
            .map((x) => {
              return x.name;
            })
            .join(" · ");
          this.popup = {
            price:
              "¥" +
              this.drivings.priceDown +
              (this.drivings.priceDown !== this.drivings.priceUp
                ? "～¥" + this.drivings.priceUp
                : ""),
            desc: "请选择：商品规格",
            title: "", //库存1000个
            thumb: this.drivings.picUrls[0] ? this.drivings.picUrls[0] : "",
          };
          this.form.shopName = this.drivings.shopInfo.name;
          this.form.name = this.drivings.name;
          this.drivings.description = this.drivings.description.replace(
            /<img/g,
            '<img class="rich-text-img"'
          );
          this.getType();
        } else {
          Toast(res.data.msg);
        }
      });
    },
    getType() {
      getType({ spuId: this.id }).then((res) => {
        if (res.data.code === 0) {
          this.typeArr = res.data.data;
          this.selectType = [];
          for (let i of this.typeArr) {
            console.log(i);
            this.selectType.push("");
          }
          if (this.typeArr.length == 0) {
            this.form.skus = this.drivings.skus[0];
          }
        }
      });
    },
    //加入购物车
    addShopCar() {
      let desc = "";
      console.log(this.form);
      for (let index = 0; index < this.form.skus.specs.length; index++) {
        let specs = this.form.skus.specs[index];
        desc = desc.concat(specs.specValueName + " ");
      }
      let form = {
        shopId: this.form.skus.shopId,
        spuId: this.form.skus.spuId,
        skuId: this.form.skus.id,
        quantity: this.form.num,
        spuName: this.drivings.name,
        addPrice: this.form.skus.salesPrice,
        specInfo: desc,
        picUrl: this.form.skus.picUrl
          ? this.form.skus.picUrl
          : this.drivings.picUrls[0],
      };
      addShopCar(form).then((res) => {
        if (res.data.code === 0) {
          this.show = false;
          Toast("加入成功");
        } else {
          Toast("加入失败");
        }
      });
    },
    //页面刷新
    pageRefresh() {
      if (this.$route.query.isFirst != null) {
        this.isFirst = this.$route.query.isFirst;
      }
      if (this.$route.query.id != null) {
        this.id = this.$route.query.id;
      }
      // 获取app传递过来的session
      if (this.$route.query.WoSession != null) {
        this.$store.commit("setSession", this.$route.query.WoSession);
      }

      this.getObjs();
      this.getComment();
    },
    //分享赚
    shareEarn() {
      if (this.form.skus) {
        if (this.form.skus == "") {
          Toast("请选择商品规格");
        } else {
          this.shareShow = true;
        }
      } else {
        Toast("此商品暂无规格");
      }
    },
    //分享赚输入格式化
    formatter(value) {
      if (value > 2) {
        Toast("加价幅度不能超过2%");
        return 2;
      }
      if (value <= 0 || value == "") {
        return 0;
      }
      return value;
    },
    //上架到我的小店
    insertMyshop() {
      let form = {
        picUrls: [this.popup.thumb],
        name: this.drivings.name,
        suggestions: this.shareForm.message,
        markup: String(Number(this.shareForm.addPrice) / 100),
        priceDown: this.form.skus.salesPrice,
        skus: [this.form.skus],
      };
      console.log(form);
      insertMyshop(form).then((res) => {
        if (res.data.code === 0) {
          Toast("上架成功");
          this.shareForm.message = "";
          this.shareForm.addPrice = "0";
          this.shareShow = false;
        } else {
          Toast("上架失败");
        }
      });
    },
    shareGoods(type) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      let obj = {
        title: this.form.name,
        picUrl: this.drivings.picUrls[0],
        content: this.form.shopName,
        openUrl: location.href,
        platformId: type,
      };
      console.log(obj);
      if (isIOS) {
        window.webkit.messageHandlers.share.postMessage(JSON.stringify(obj));
      }
      if (isAndroid) {
        window.android.share(
          obj.platformId,
          "3",
          obj.title,
          obj.content,
          obj.openUrl,
          obj.picUrl
        );
      }
      this.shareBoxShow = false;
    },
    shouchang() {
      this.collection({
        type: "1",
        relationId: this.drivings.id,
        source: "1",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$toast.success("收藏成功");
          this.getObjs();
        } else {
          this.$$toast.fail("收藏失败");
        }
      });
    },
    cancelCellect() {
      this.cCT(this.drivings.collectId).then((res) => {
        if (res.data.code == 0) {
          this.getObjs();
        }
      });
    },
    async getComment() {
      let res = await getComment({ spuId: this.id, current: this.current });
      console.log(res);
      this.evaluateList = res.data.data.records;
    },
  },
};
</script>
<style lang="less" scoped>
.detailsGoods {
  background-color: #f6f6f6;
  .goods-box {
    border-radius: 10px;
    margin: 10px;
    overflow: hidden;
    background-color: #fff;
  }
}
.details-Number {
  display: flex;
  //  border: 1px solid red;
  :first-child {
    margin-top: 10px;
  }
  :last-child {
    margin-top: 2px;
  }
}
.detailsGoods .van-card {
  background-color: rgb(255, 255, 255);
  padding: 0;
  flex: 1;
}
.detailsGoods .content {
  padding: 16px 16px 160px;
}
.detailsGoods .van-multi-ellipsis--l2 {
  .van-card__header {
    display: flex;
    flex-direction: column;
  }
}

.detailsGoods .van-card__content {
  flex-direction: column-reverse;
  min-height: 0;
}
.detailsGoods .van-card__thumb {
  margin: 0;
  width: 90px;
  height: 90px;
}

.detailsGoods .choses {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: #ffffff;
}
.detailsGoods .goods {
  padding: 50px 0 80px;

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }
  .back {
    background: url(/assets/编组8.png);
  }
  .flex-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .flex-cur {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    flex-direction: column;
  }
  .flex-cur img {
    width: 50px;
    height: auto;
  }

  .shop-list-box {
    /* padding:0 10px; */
    background: white;
  }

  .shop-detail-img {
    padding-right: 10px;
  }
  .shop-detail-img img {
    width: 2rem;
    height: auto;
  }
  .shop-detail-list {
    padding-top: 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.01rem solid #efeff4;
  }
  .shop-detail-intro {
    flex: 1;
    height: 100%;
  }
  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;
  }

  &-tag {
    margin-left: 5px;
  }
}
.detailsGoods .info {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  text-align: center;
  align-self: center;
  margin-top: 20px;
}
.detailsGoods .info1 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  text-align: center;
  align-self: center;
  background-color: #f70035;
}
.detailsGoods .info2 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
  align-self: center;
  margin-bottom: 20px;
  padding: 15px;
}

.detailsGoods .icon2 {
  width: 23px;
  height: 23px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.detailsGoods .info5 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 11px;
  letter-spacing: -0.30000001192092896px;
  font-family: PingFangSC-Regular;
  line-height: 16px;
  text-align: center;
  align-self: center;
  margin-top: 11px;
}
.detailsGoods .info6 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
  align-self: center;
  padding: 10px;
}

.detailsGoods .word14 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  letter-spacing: -1.2000000476837158px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
  align-self: center;
  margin-top: 9px;
}

.detailsGoods .outer6 {
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2e0399f157d37a6b197ee7e58871592ec12ce1e296f4a69df189dcf12ed453b4)
    100% no-repeat;
  align-self: center;
  margin-top: 10px;
  justify-content: center;
  align-items: flex-end;
  padding-right: 10px;
  position: flex;
  height: 80px;
}

.detailsGoods .custom-indicator {
  position: absolute;
  right: 16px;
  bottom: 30px;
  font-size: 11px;
  background: rgba(0, 0, 0, 0.3);
  height: 22px;
  width: 34px;
  border-radius: 11px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailsGoods .noSelect {
  background: #eeeeee;
  border-radius: 5px;
  margin-right: 15px;
  font-size: 12px;
  color: #999999;
}

.detailsGoods .select {
  background-image: linear-gradient(to right, #ff7200, #ff3c00);
  border-radius: 5px;
  margin-right: 15px;
  font-size: 12px;
  color: #ffffff;
}

.detailsGoods .popCard {
  // position: sticky;
  // top: 0;
  // top: 23px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  background: #ffffff;
  padding-left: 10px;
  padding-top: 23px;
  padding-bottom: 23px;
}

.detailsGoods .van-cell::after {
  border: none;
}

.bottom-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

/deep/ .rich-text-img {
  display: block;
}
/deep/ .van-cell__title {
  width: calc(100% - 20px);
}
.star {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
</style>

import {fetchGet} from '../../router/https'
function getHotelData(data){
    return fetchGet('/mallapp/hotelroom/getRoomList',data)
}
function getHotelInfo(data){
    return fetchGet('/mallapp/hotelroom/hotel',data)
}
function getComment(data){
   
    return fetchGet('/mallapp/goodsappraises/page',data)
}
export{
    getHotelData,
    getHotelInfo,
    getComment
}
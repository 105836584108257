<template>
  <div class="evaluate-item">
    <van-image
      width="55px"
      height="55px"
      :src="value.headimgUrl ? value.headimgUrl : src"
      fit="cover"
      class="image"
    />
    <div class="right">
      <div class="title">
        <div style="font-size: 14px; color: #333">{{ value.nickName }}</div>
        <div>
          {{ value.createTime }}
        </div>
      </div>

      <div
        v-for="(item, index) in scores"
        :key="index"
        style="line-height: 1.8"
      >
        <span style="color: #333">{{ item.name }}</span>
        <van-icon
          name="star"
          v-for="item2 in item.value"
          :key="item2 + item + ''"
          color="#ff5200"
        />
        <van-icon
          name="star-o"
          v-for="item3 in item.value2"
          :key="item3"
          color="#ff5200"
        />
        <span style="color: #ff5200">
          <span>{{ item.value }}</span>
          <span>分</span>
        </span>
      </div>
      <div style="margin-top: 10px">{{ value.content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "evaluateItem",
  props: {
    value: {},
  },
  components: {},
  data() {
    return {
      src: require("@/assets/fitment/default.png"),
    };
  },
  created() {},
  mounted() {},
  computed: {
    scores() {
      let arr = [
        {
          name: "宝贝描述",
          value: this.value.goodsScore ? +this.value.goodsScore : 0,
          value2: this.value.goodsScore ? 5 - this.value.goodsScore : 5,
        },
        {
          name: "卖家服务",
          value: this.value.serviceScore ? +this.value.serviceScore : 0,
          value2: this.value.serviceScore ? 5 - this.value.serviceScore : 5,
        },
        {
          name: "物流服务",
          value: this.value.logisticsScore ? +this.value.logisticsScore : 0,
          value2: this.value.logisticsScore ? 5 - this.value.logisticsScore : 5,
        },
      ];
      return arr;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang='less' scoped>
.evaluate-item {
  display: flex;
  padding: 20px 0;
  margin: 0 16px;
  border-bottom: 1px solid #e5e5e5;
  .image {
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;
  }
  .right {
    flex: 1;
    font-size: 12px;
    color: #999999;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
}
</style>